export const useFavoriteRestaurant = (restaurantCode?: string) => {
  const authStore = useAuthStore()
  const countryCode = useCountry()

  const { trackAddFavorites, trackRemoveFavorites } = useGTM()

  const isFavorite = computed(() =>
    isFavoriteRestaurant({
      countryCode,
      favoriteRestaurantsMetadata: authStore?.userMetadata?.favoriteRestaurants,
      restaurantCode,
    })
  )

  const toggleFavorite = () => {
    if (!restaurantCode) return
    let data
    if (isFavorite.value) {
      data = removeRestaurantsFromFavorite({
        favoriteRestaurantsMetadata: authStore?.userMetadata?.favoriteRestaurants,
        countryCode,
        restaurantCode,
      })
      trackRemoveFavorites()
    } else {
      data = addRestaurantsToFavorite({
        favoriteRestaurantsMetadata: authStore?.userMetadata?.favoriteRestaurants,
        countryCode,
        restaurantCode,
      })
      trackAddFavorites()
    }
    authStore.updateUser({ favoriteRestaurants: JSON.stringify(data) })
  }

  return { isFavorite, toggleFavorite }
}
